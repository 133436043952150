import { createStore } from 'vuex';
import tarifrechner from '@/store/tarifrechner';
import menu from '@/store/menu';
import axios, { AxiosError, AxiosResponse } from 'axios';

//const api = 'http://localhost:4000/';
const api = 'https://werbung.kleinezeitung.at/api/';

interface APIRequest {
  method: 'get' | 'delete' | 'post' | 'put' | 'patch';
  url: string;
  data?: any;
  config?: any;
}

export default createStore({
  modules: {
    tarifrechner,
    menu,
  },
  state: {
    dropdownstate: {},
    modalstate: {},
    collapsiblestate: {},
    accordionstate: {},
    menustate: true,
    searchstate: false,
    windowsize: window.innerWidth,
    searchtexxt: '',
  },
  mutations: {
    // toggleSearch(state) {
    //   state.menustate = !state.menustate;
    // },
    // toggleMenu(state) {
    //   state.searchstate = !state.searchstate;
    // },
  },
  actions: {
    API(context, { method, url, data, config }: APIRequest) {
      switch (method) {
        case 'get':
          return axios.get(api + url, config);
          break;
        case 'delete':
          return axios.delete(api + url, config);
          break;
        case 'post':
          return axios.post(api + url, data, config);
          break;
        case 'put':
          return axios.put(api + url, data, config);
          break;
        case 'patch':
          return axios.patch(api + url, data, config);
          break;
      }
    },
    getPage(context, id: number) {
      return context.dispatch('API', { method: 'get', url: `page/${id}` });
    },
    searchPage(context, needle: string) {
      return context.dispatch('API', { method: 'post', url: 'page', data: { search: needle } });
    },
    sendEmail(
      context,
      payload: {
        firstname: string;
        lastname: string;
        company: string;
        email: string;
        telephone: string;
        interest: string;
        branchsolution: string;
        text: string;
      }
    ) {
      return context.dispatch('API', { method: 'post', url: 'contact/contact', data: payload });
    },
    sendEmailLanding(
      context,
      payload: {
        firstname: string;
        lastname: string;
        company: string;
        email: string;
        telephone: string;
        interest: string;
        branchsolution: string;
        text: string;
      }
    ) {
      return context.dispatch('API', { method: 'post', url: 'contact/landingpage', data: payload });
    },
  },
  getters: {
    // getDropDownState: (state) => (id: string) => {
    //   return state.dropdownstate.find(todo => todo.id === id);
    // },
    getSearchtext: state => {
      return state.searchstate;
    },
  },
});
