const state = {
  items: {
    raw: [],
    flat: [],
    hierarchical: [],
  },
};

const getters = {};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
