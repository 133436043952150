const state = {
  produkte: {
    Beilage: {
      'Rota 42.5g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 12,
            gewicht: 17,
            grundpreis: 1324.38,
            weiteretausend: 41.95,
          },
          {
            seiten: 16,
            gewicht: 23,
            grundpreis: 1375.01,
            weiteretausend: 48.54,
          },
          {
            seiten: 20,
            gewicht: 29,
            grundpreis: 1623.28,
            weiteretausend: 55.15,
          },
          {
            seiten: 24,
            gewicht: 34,
            grundpreis: 1673.83,
            weiteretausend: 61.73,
          },
          {
            seiten: 28,
            gewicht: 40,
            grundpreis: 2096.8,
            weiteretausend: 68.33,
          },
          {
            seiten: 32,
            gewicht: 46,
            grundpreis: 1775.16,
            weiteretausend: 74.92,
          },
          {
            seiten: 36,
            gewicht: 52,
            grundpreis: 2503.04,
            weiteretausend: 87.84,
          },
          {
            seiten: 40,
            gewicht: 57,
            grundpreis: 2886.63,
            weiteretausend: 101.06,
          },
          {
            seiten: 44,
            gewicht: 63,
            grundpreis: 2187.52,
            weiteretausend: 107.65,
          },
          {
            seiten: 48,
            gewicht: 69,
            grundpreis: 2187.52,
            weiteretausend: 107.65,
          },
          {
            seiten: 56,
            gewicht: 80,
            grundpreis: 2438.77,
            weiteretausend: 136.73,
          },
          {
            seiten: 64,
            gewicht: 92,
            grundpreis: 2628.4,
            weiteretausend: 149.96,
          },
        ],
      },
      'Rota 55g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 15,
            grundpreis: 1303.69,
            weiteretausend: 41.35,
          },
          {
            seiten: 12,
            gewicht: 22,
            grundpreis: 1369.5,
            weiteretausend: 50.97,
          },
          {
            seiten: 16,
            gewicht: 30,
            grundpreis: 1435.11,
            weiteretausend: 60.56,
          },
          {
            seiten: 20,
            gewicht: 37,
            grundpreis: 1698.45,
            weiteretausend: 70.17,
          },
          {
            seiten: 24,
            gewicht: 45,
            grundpreis: 1763.98,
            weiteretausend: 79.76,
          },
          {
            seiten: 28,
            gewicht: 52,
            grundpreis: 2201.96,
            weiteretausend: 89.37,
          },
          {
            seiten: 32,
            gewicht: 59,
            grundpreis: 1895.35,
            weiteretausend: 98.95,
          },
          {
            seiten: 36,
            gewicht: 67,
            grundpreis: 2638.34,
            weiteretausend: 114.9,
          },
          {
            seiten: 40,
            gewicht: 74,
            grundpreis: 2200.76,
            weiteretausend: 124.4,
          },
          {
            seiten: 44,
            gewicht: 82,
            grundpreis: 3052.03,
            weiteretausend: 134.14,
          },
          {
            seiten: 48,
            gewicht: 89,
            grundpreis: 2367.94,
            weiteretausend: 143.74,
          },
          {
            seiten: 56,
            gewicht: 104,
            grundpreis: 2649.28,
            weiteretausend: 178.83,
          },
          {
            seiten: 64,
            gewicht: 119,
            grundpreis: 2869.05,
            weiteretausend: 198.09,
          },
        ],
      },
      'Rota 80.0g': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 4,
            gewicht: 11,
            grundpreis: 1525.19,
            weiteretausend: 48.97,
          },
          {
            seiten: 8,
            gewicht: 22,
            grundpreis: 1659.81,
            weiteretausend: 71.7,
          },
          {
            seiten: 12,
            gewicht: 32,
            grundpreis: 1794.32,
            weiteretausend: 94.41,
          },
          {
            seiten: 16,
            gewicht: 43,
            grundpreis: 1929.05,
            weiteretausend: 117.16,
          },
          {
            seiten: 20,
            gewicht: 54,
            grundpreis: 2297.85,
            weiteretausend: 139.86,
          },
          {
            seiten: 24,
            gewicht: 65,
            grundpreis: 2432.36,
            weiteretausend: 162.57,
          },
          {
            seiten: 32,
            gewicht: 86,
            grundpreis: 2701.49,
            weiteretausend: 208.01,
          },
          {
            seiten: 40,
            gewicht: 108,
            grundpreis: 3760.35,
            weiteretausend: 260.9,
          },
          {
            seiten: 48,
            gewicht: 130,
            grundpreis: 4052.42,
            weiteretausend: 310.93,
          },
        ],
      },
      'LWC glänzend': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 17,
            grundpreis: 421.32,
            weiteretausend: 38.96,
          },
          {
            seiten: 12,
            gewicht: 26,
            grundpreis: 538.59,
            weiteretausend: 55.95,
          },
          {
            seiten: 16,
            gewicht: 35,
            grundpreis: 674.8,
            weiteretausend: 70.24,
          },
          {
            seiten: 20,
            gewicht: 44,
            grundpreis: 1014.7,
            weiteretausend: 86.49,
          },
          {
            seiten: 24,
            gewicht: 52,
            grundpreis: 1132.89,
            weiteretausend: 101.34,
          },
          {
            seiten: 28,
            gewicht: 61,
            grundpreis: 1320.02,
            weiteretausend: 116.6,
          },
          {
            seiten: 32,
            gewicht: 70,
            grundpreis: 1436.88,
            weiteretausend: 131.18,
          },
          {
            seiten: 40,
            gewicht: 87,
            grundpreis: 1429.8,
            weiteretausend: 164.59,
          },
          {
            seiten: 48,
            gewicht: 105,
            grundpreis: 1621.89,
            weiteretausend: 194.21,
          },
          {
            seiten: 56,
            gewicht: 122,
            grundpreis: 1885.98,
            weiteretausend: 224.33,
          },
          {
            seiten: 64,
            gewicht: 140,
            grundpreis: 2077.31,
            weiteretausend: 253.81,
          },
          {
            seiten: 72,
            gewicht: 158,
            grundpreis: 2325.43,
            weiteretausend: 283.43,
          },
        ],
      },
      'LWC matt': {
        type: 'paged',
        bild: 'produkt-beilage.png',
        kategoriename: 'Beilage',
        verarbeitung: 'beilagen',
        items: [
          {
            seiten: 8,
            gewicht: 16,
            grundpreis: 412.98,
            weiteretausend: 37.75,
          },
          {
            seiten: 12,
            gewicht: 24,
            grundpreis: 526.89,
            weiteretausend: 54.12,
          },
          {
            seiten: 16,
            gewicht: 32,
            grundpreis: 659.42,
            weiteretausend: 67.82,
          },
          {
            seiten: 20,
            gewicht: 41,
            grundpreis: 993.65,
            weiteretausend: 83.45,
          },
          {
            seiten: 24,
            gewicht: 49,
            grundpreis: 1108.36,
            weiteretausend: 97.68,
          },
          {
            seiten: 28,
            gewicht: 57,
            grundpreis: 1291.44,
            weiteretausend: 112.36,
          },
          {
            seiten: 32,
            gewicht: 65,
            grundpreis: 1404.83,
            weiteretausend: 126.33,
          },
          {
            seiten: 40,
            gewicht: 81,
            grundpreis: 1393.28,
            weiteretausend: 158.5,
          },
          {
            seiten: 48,
            gewicht: 97,
            grundpreis: 1578.84,
            weiteretausend: 186.9,
          },
          {
            seiten: 56,
            gewicht: 114,
            grundpreis: 1835.91,
            weiteretausend: 215.85,
          },
          {
            seiten: 64,
            gewicht: 130,
            grundpreis: 2020.74,
            weiteretausend: 244.11,
          },
          {
            seiten: 72,
            gewicht: 146,
            grundpreis: 2262.29,
            weiteretausend: 272.62,
          },
        ],
      },
    },
    Mantel: {
      'Rota 42.5g': {
        type: 'noprint',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'bundesland',
      },
      LWC: {
        type: 'single',
        bild: 'produkt-mantel.png',
        kategoriename: 'Mantel',
        verarbeitung: 'mantel',
        items: [
          {
            menge: 5000,
            grundpreis: 817.5,
            weiteretausend: 104.37,
          },
          {
            menge: 10000,
            grundpreis: 1239.75,
            weiteretausend: 93.58,
          },
          {
            menge: 20000,
            grundpreis: 2002,
            weiteretausend: 84.98,
          },
          {
            menge: 30000,
            grundpreis: 2639.25,
            weiteretausend: 81.89,
          },
          {
            menge: 40000,
            grundpreis: 3204.5,
            weiteretausend: 78.66,
          },
          {
            menge: 50000,
            grundpreis: 3743.75,
            weiteretausend: 75.64,
          },
          {
            menge: 100000,
            grundpreis: 6958.8,
            weiteretausend: 72.61,
          },
          {
            menge: 200000,
            grundpreis: 13282.5,
            weiteretausend: 69.59,
          },
          {
            menge: 300000,
            grundpreis: 19722.5,
            weiteretausend: 69.59,
          },
        ],
      },
    },
    'Tip on Card': {
      none: {
        bild: 'produkt-tiponcard.png',
        kategoriename: 'Tip on Card',
        type: 'single',
        verarbeitung: 'tipon',
        items: [
          {
            menge: 5000,
            grundpreis: 255,
            weiteretausend: 15.9,
          },
          {
            menge: 10000,
            grundpreis: 330,
            weiteretausend: 15.9,
          },
          {
            menge: 20000,
            grundpreis: 487.5,
            weiteretausend: 15.9,
          },
          {
            menge: 30000,
            grundpreis: 630,
            weiteretausend: 15.9,
          },
          {
            menge: 40000,
            grundpreis: 732.5,
            weiteretausend: 15.37,
          },
          {
            menge: 50000,
            grundpreis: 875,
            weiteretausend: 14.7,
          },
          {
            menge: 100000,
            grundpreis: 1417.5,
            weiteretausend: 13.37,
          },
          {
            menge: 200000,
            grundpreis: 2405,
            weiteretausend: 12.61,
          },
          {
            menge: 300000,
            grundpreis: 3112.5,
            weiteretausend: 12,
          },
          {
            menge: 400000,
            grundpreis: 3900,
            weiteretausend: 11.46,
          },
        ],
      },
    },
    Flyer: {
      none: {
        type: 'single',
        bild: 'produkt-flyer.png',
        kategoriename: 'Flyer',
        verarbeitung: 'flyer',
        items: [
          {
            menge: 5000,
            grundpreis: 472.5,
            weiteretausend: 45.44,
          },
          {
            menge: 10000,
            grundpreis: 682.5,
            weiteretausend: 42.56,
          },
          {
            menge: 20000,
            grundpreis: 1029.5,
            weiteretausend: 38.31,
          },
          {
            menge: 30000,
            grundpreis: 1370.25,
            weiteretausend: 36.92,
          },
          {
            menge: 40000,
            grundpreis: 1652,
            weiteretausend: 34.8,
          },
          {
            menge: 50000,
            grundpreis: 1946,
            weiteretausend: 34.73,
          },
          {
            menge: 100000,
            grundpreis: 3107,
            weiteretausend: 32.15,
          },
          {
            menge: 200000,
            grundpreis: 5514,
            weiteretausend: 29.68,
          },
          {
            menge: 300000,
            grundpreis: 8148,
            weiteretausend: 29.68,
          },
        ],
      },
    },
    Folder: {
      none: {
        type: 'single',
        bild: 'produkt-folder.png',
        kategoriename: 'Folder',
        verarbeitung: 'folder',
        items: [
          {
            menge: 5000,
            grundpreis: 675,
            weiteretausend: 84.89,
          },
          {
            menge: 10000,
            grundpreis: 1042.5,
            weiteretausend: 76.65,
          },
          {
            menge: 20000,
            grundpreis: 1702.5,
            weiteretausend: 70.71,
          },
          {
            menge: 30000,
            grundpreis: 2280,
            weiteretausend: 70.44,
          },
          {
            menge: 40000,
            grundpreis: 2832,
            weiteretausend: 70.44,
          },
          {
            menge: 50000,
            grundpreis: 3300.64,
            weiteretausend: 65.74,
          },
          {
            menge: 100000,
            grundpreis: 6117.28,
            weiteretausend: 61.05,
          },
          {
            menge: 200000,
            grundpreis: 11281.92,
            weiteretausend: 56.35,
          },
          {
            menge: 300000,
            grundpreis: 16917.12,
            weiteretausend: 56.35,
          },
        ],
      },
    },
  },
  verarbeitung: {
    beilagen: {
      wochentag: [
        {
          gewicht: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
        {
          gewicht: 40,
          preisunter80k: 127.65,
          preisab80k: 125.8,
        },
        {
          gewicht: 60,
          preisunter80k: 139.35,
          preisab80k: 137.4,
        },
        {
          gewicht: 80,
          preisunter80k: 151.3,
          preisab80k: 149.05,
        },
        {
          gewicht: 100,
          preisunter80k: 172,
          preisab80k: 169.7,
        },
        {
          gewicht: 120,
          preisunter80k: 185.3,
          preisab80k: 183,
        },
      ],
      sonntag: [
        {
          gewicht: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
        {
          gewicht: 40,
          preisunter80k: 133.15,
          preisab80k: 131.2,
        },
        {
          gewicht: 60,
          preisunter80k: 145.4,
          preisab80k: 143.25,
        },
        {
          gewicht: 80,
          preisunter80k: 157.7,
          preisab80k: 155.35,
        },
        {
          gewicht: 100,
          preisunter80k: 179.2,
          preisab80k: 176.1,
        },
        {
          gewicht: 120,
          preisunter80k: 193,
          preisab80k: 189.9,
        },
      ],
    },
    mantel: {
      wochentag: [
        {
          gewicht: 20,
          preisunter80k: 415.8,
          preisab80k: 409.5,
        },
        {
          gewicht: 40,
          preisunter80k: 446.78,
          preisab80k: 440.3,
        },
      ],
      sonntag: [
        {
          gewicht: 20,
          preisunter80k: 433.65,
          preisab80k: 427.35,
        },
        {
          gewicht: 40,
          preisunter80k: 466.03,
          preisab80k: 459.2,
        },
      ],
    },
    tipon: {
      perforation: {
        keine: 0,
        '1x': 400,
        '2x': 500,
      },
      titelseite: {
        wochentag: {
          preisunter80k: 166.32,
          preisab80k: 163.8,
        },
        sonntag: {
          preisunter80k: 173.46,
          preisab80k: 170.94,
        },
      },
      u4: {
        wochentag: {
          preisunter80k: 130.68,
          preisab80k: 128.7,
        },
        sonntag: {
          preisunter80k: 136.29,
          preisab80k: 134.31,
        },
      },
    },
    flyer: {
      wochentag: [
        {
          gewicht: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          gewicht: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    folder: {
      wochentag: [
        {
          gewicht: 20,
          preisunter80k: 118.8,
          preisab80k: 117,
        },
      ],
      sonntag: [
        {
          gewicht: 20,
          preisunter80k: 123.9,
          preisab80k: 122.1,
        },
      ],
    },
    bundesland: {
      zweiseitig: {
        kombi: {
          wochentag: 102370.0,
          sonntag: 107488.5,
        },
        steiermark: {
          wochentag: 69611.6,
          sonntag: 73092.18,
        },
        kaernten: {
          wochentag: 34805.8,
          sonntag: 36546.09,
        },
      },
      vierseitig: {
        kombi: {
          wochentag: 115160.7,
          sonntag: 120918.74,
        },
        steiermark: {
          wochentag: 78309.28,
          sonntag: 82224.74,
        },
        kaernten: {
          wochentag: 39154.64,
          sonntag: 41112.37,
        },
      },
    },
  },
};

const getters = {};
const mutations = {};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
